import { Flex } from '@/components'
import { styled } from '@punto-ui/react'

// display da tela
export const ScreenRoot = styled(Flex, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  background:
    'linear-gradient(270deg, #005FC8 0%, #0E78ED 78.85%, #127EF8 100%)',
})

export const Card = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '32vw',
  padding: '$12',
  background: '#FFFFFF',
  boxShadow: '0px 0px 24px rgba(52, 58, 64, 0.16)',
  borderRadius: '12px',
  margin: 180,
  gap: 15,
  marginLeft: 50,
})

export const Frame1 = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  minWidth: '30vw',
  width: '100%',
})

export const Frame2 = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  flex: 1,
})
