import { z } from 'zod'

export const loginFormSchema = z.object({
  email: z
    .string()
    .min(1, 'O e-mail deve ter pelo menos 1 caractere')
    .email('O e-mail fornecido é inválido'),
  password: z.string().min(1, 'A senha deve ter pelo menos 1 caractere'),
})

export type LoginFormData = z.infer<typeof loginFormSchema>
