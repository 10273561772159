import { Button, Text, TextInput } from '@punto-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAuth } from '@/contexts/hooks'
import { LoginFormData, loginFormSchema } from './forms'
import logo from '@/assets/img/logo.png'
import logoRegister from '@/assets/img/logoRegister.png'
import useToast from '@/hooks/useToast'

import {
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'

import { Card, Frame1, Frame2, ScreenRoot } from './styles'
import { Flex } from '@/components'
import Image from 'next/image'
import { useRouter } from 'next/router'

export default function Home() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginFormSchema),
  })

  const [isLoading, setIsLoading] = useState(false)
  const { signIn } = useAuth()
  const router = useRouter()
  const { addToast } = useToast()

  const handleLogin = async (data: LoginFormData) => {
    setIsLoading(true)

    try {
      await signIn(data)
      addToast({
        title: '👌éxitos',
        description: 'Serás redirigido a la página de inicio.',
        type: 'positive',
        id: 1,
      })
    } catch (data) {
      addToast({
        title: '❌ Uppz!',
        description: '¡Fracasado! Inténtalo de nuevo',
        type: 'negative',
        id: 1,
      })
    }

    setIsLoading(false)
  }

  const [showPassword, setShowPassword] = useState(false)

  return (
    <ScreenRoot>
      <Frame1>
        <form>
          <Flex css={{ flexDirection: 'column' }}>
            <Card>
              <Flex
                css={{
                  gap: '$2',
                  justifyContent: 'speace-between',
                  alignItems: 'center',
                  marginBottom: '$4',
                  display: 'flex',
                }}
              >
                <ArrowRightOnRectangleIcon
                  style={{
                    marginRight: 8,
                    width: 30,
                    color: '#50A2FF',
                    strokeWidth: 2,
                    borderRadius: 8,
                  }}
                />
                <Text
                  variant={'display4'}
                  css={{
                    color: '$interface_dark_down',
                    fontSize: 32,
                  }}
                >
                  Login
                </Text>
              </Flex>
              <TextInput
                placeholder="E-mail"
                icon={<EnvelopeIcon />}
                label="E-mail"
                css={{
                  width: '23vw',
                  display: 'flex',
                }}
                {...register('email')}
                {...((errors.email && { error: errors.email.message }) || {})}
              />
              <TextInput
                placeholder="Contraseña"
                icon={
                  showPassword ? (
                    <EyeSlashIcon onClick={() => setShowPassword(false)} />
                  ) : (
                    <EyeIcon onClick={() => setShowPassword(true)} />
                  )
                }
                label="Contraseña"
                css={{
                  width: '23vw',
                  display: 'flex',
                }}
                type={showPassword ? 'text' : 'password'}
                {...register('password')}
              />

              <Button
                type="submit"
                css={{
                  width: '100%',
                }}
                disabled={isLoading}
                onClick={handleSubmit(handleLogin)}
              >
                Ingresar
              </Button>

              <Text
                onClick={() => {
                  router.push('/auth/register')
                }}
                css={{
                  color: '$interface_dark_up',
                  fontSize: 16,
                  textAlign: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                }}
              >
                ¿No tienes una cuenta? Registro
              </Text>
            </Card>
          </Flex>
        </form>
      </Frame1>
      <Frame2>
        <Flex
          css={{
            gap: '$0 ',
            flexDirection: 'row',
            position: 'relative',
            width: '35vw',
            height: '100vh',
            margin: 180,
            marginLeft: -120,
            display: 'flex',
          }}
        >
          <Flex css={{ flexDirection: 'column', gap: '$6', display: 'flex' }}>
            <Image src={logo} alt="Punto Ok" width={350} />
            <Text
              variant={'display3'}
              css={{
                color: '$brand_complementary_pure',
                width: 350,
              }}
            >
              Un panel completo para la gestión de RR.HH
            </Text>
          </Flex>
          <Image src={logoRegister} alt="Punto Ok" width={400} />
        </Flex>
      </Frame2>
    </ScreenRoot>
  )
}
